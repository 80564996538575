import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  h1 {
    font-family: 'Citrina', Helvetica, sans-serif;
  }

  align-items: center;
  display: flex;

  h1 {
    color: ${orange[500]};
  }

  @media ${device.tablet} {
    min-height: 428px;
  }

  @media ${device.desktopLG} {
    min-height: 468px;
  }

  @media ${device.desktopXL} {
    min-height: 610px;
  }

  button {
    height: 48px;
    @media ${device.tablet} {
      max-width: 356px;
    }

    @media ${device.desktopLG} {
      max-width: 532px;
    }

    @media ${device.desktopXL} {
      max-width: 577px;
    }
  }
`
