import React from 'react'

import IconsSwitch from 'src/components/IconsSwitch/_index'
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import * as S from './style'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IProps {
  openModal: () => void;
  oneLink: string;
}

type ItemProps = {
  icon: string;
  title: string;
  description: string;
}

const InvistaDinheiroSeuFilho = ({ openModal, oneLink }: IProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <S.Section className='py-5 bg-grayscale--100'>
      <div className='container mt-lg-5'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-xl-1 d-flex justify-content-center justify-content-xl-end order-md-last'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/kids-dobra4/image.webp'
              altDescription='Tela de celular mostrando a área de Investimentos no super app Inter'
              arrayNumbers={[ 312, 340, 340, 450 ]}
              arrayNumbersHeight={[ 331, 369, 369, 488 ]}
            />
          </div>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6 mt-3 px-lg-0'>
            <h2 className='fs-24 fs-md-30 fs-xl-32 lh-28 lh-md-36 lh-xl-38 text-grayscale--500'>
              Invista e faça o dinheiro do seu filho render
            </h2>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-19 lh-xl-22 text-grayscale--400 mb-0 mb-md-2'>
              Temos uma plataforma completa pra você investir o dinheiro do seu filho e planejar o futuro desde já.
            </p>
            <div className='row mt-md-4'>
              <div className='col-12 col-lg-6'>
                <div className='d-flex align-items-center bg-white rounded-2 mt-4 mt-md-0 mb-3 px-3 py-3'>
                  <S.Icon className='mr-2 d-flex align-items-center justify-content-center'>
                    <IconsSwitch icon='ic_coin_check' lib='interco' customPath='finance/' width='24' height='24' color='#EA7100' />
                  </S.Icon>
                  <div><p className='fs-14 fs-md-16 lh-16 lh-md-19 mb-0 fw-700 text-grayscale--500'>Renda Fixa</p></div>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='d-flex align-items-center bg-white rounded-2 px-3 py-3'>
                  <S.Icon className='mr-2 d-flex align-items-center justify-content-center'>
                    <IconsSwitch icon='ic_trending_up' lib='interco' customPath='finance/' width='24' height='24' color='#EA7100' />
                  </S.Icon>
                  <div><p className='fs-14 fs-md-16 lh-16 lh-md-19 mb-0 fw-700 text-grayscale--500'>Fundos de investimento</p></div>
                </div>
              </div>
            </div>
            <p className='text-grayscale--400 fs-14 fs-md-16 lh-16 lh-md-22 mb-0 mt-3 mt-md-3'>
              Quer saber mais sobre como abrir a Conta Kids?
              <a
                className='fw-600 ml-2 mt-2'
                href='https://blog.inter.co/passo-a-passo-para-abrir-uma-conta-digital-para-menores-de-18-anos/'
                target='_blank'
                rel='noreferrer'
                title='Acessar o nosso blog'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    element_action: 'click button',
                    element_name: 'Veja no nosso blog',
                    section_name: 'Invista e faça o dinheiro do seu filho render',
                    redirect_url: 'https://blog.inter.co/passo-a-passo-para-abrir-uma-conta-digital-para-menores-de-18-anos/',
                  })
                }}
              >
                Veja no nosso blog.
              </a>
            </p>
            {width >= WIDTH_MD ? (
              <button
                className='btn text-white btn-orange--extra text-none mt-2 mt-md-4'
                title='Abrir Conta Kids gratuita'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Invista e faça o dinheiro do seu filho render',
                    element_action: 'click button',
                    element_name: 'Abrir Conta Kids gratuita',
                  })
                }}
              >
                Abrir Conta Kids gratuita
              </button>
            ) : (
              <a
                href={oneLink}
                title='Abrir Conta Kids gratuita'
                className='btn text-white btn-orange--extra text-none mt-3 link'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Invista e faça o dinheiro do seu filho render',
                    element_action: 'click button',
                    element_name: 'Abrir Conta Kids gratuita',
                    redirect_url: oneLink,
                  })
                }}
              >
                Abrir Conta Kids gratuita
              </a>
            )}
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default InvistaDinheiroSeuFilho
